import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';

function MainImage() {
  return (
    <div className={styles.imageContainer}>
      <StaticImage className={styles.img} src="../../../../images/locations.jpg" alt="" />
      <div className={styles.overlay}>
        <h1>Contact Us</h1>
      </div>
    </div>
  );
}

export { MainImage };
