import { object } from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './location-card.module.scss';

function LocationCard({ data }) {
  return (
    <article className={styles.cardContainer}>
      <div className={styles.imageContainer}>
        <a className={styles.link} href={data.uri}>
          {' '}
        </a>
        <GatsbyImage
          image={getImage(data?.featuredImage?.node?.gatsbyImage)}
          alt="location card"
          className={styles.image}
        />
        <div className={styles.contentContainer}>
          <h4 className={styles.title}>
            <a href={data.uri}>{data.title}</a>
          </h4>
          <p className={styles.phone}>
            <a href={`tel:1${data.locationSingleConfiguration.eaLocationPhone}`}>
              {data.locationSingleConfiguration.eaLocationPhone}
            </a>
          </p>
        </div>
      </div>
    </article>
  );
}

LocationCard.propTypes = {
  data: object,
};

LocationCard.defaultProps = {
  data: {},
};

export { LocationCard };
