import { useState } from 'react';
import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { MainImage } from '../../components/pages/locations/main-image';
import { Contact } from '../../components/common/contact';
import { Appointment } from '../../components/legal-journey-appointment';

import * as styles from './index.module.scss';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/misc';

function Locations({ data }) {
  const [stateSelected, setStateSelected] = useState(getLocalStorageItem('blogPostStateKey') || '');

  const updateStateSelected = (state) => {
    setLocalStorageItem('blogPostStateKey', state);
    setStateSelected(state);
  };

  return (
    <>
      <MainImage />
      <div className={styles.iconsContainer}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h2>Schedule a Case Assessment</h2>
          </div>
          <Contact state={stateSelected} />
        </div>
      </div>
      <Appointment
        data={data?.allWpLocation?.nodes}
        text="Office Locations"
        state={stateSelected}
        updateState={updateStateSelected}
      />
    </>
  );
}

export const query = graphql`
  {
  wpPage(uri: {eq: "/locations/"}) {
    ...SeoPage
  }
    allWpLocation {
      nodes {
        title
          uri
          featuredImage {
            node {
              gatsbyImage(width:350)
            }
          }
          locationSingleConfiguration {
            eaLocationPhone
            eaLocationState
          }
      }
    }
  }
`;

Locations.propTypes = {
  data: object,
};

Locations.defaultProps = {
  data: {},
};

export default Locations;
export { Head } from '../../components/seo/seo';
