import { array, func, string } from 'prop-types';
import { useEffect, useState } from 'react';
import * as selectStyles from '../common/custom-select/select.module.scss';
import * as styles from './appointment.module.scss';
import { Wrap } from '../sections-wrap';
import { Select } from '../common/custom-select';
import { addNotDuplicatedArrayString } from '../../helpers';
import { LocationCard } from '../location-card';
import { getLocalStorageItem } from '../../helpers/misc';

function Appointment({
  text, data, state, updateState,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [locations, setLocations] = useState(data);

  useEffect(() => {
    if (state === 'Any' || state === 'All states' || !state) {
      setLocations(data);
    } else if (state) {
      setLocations(data.filter((location) => location.locationSingleConfiguration.eaLocationState
       === state));
    }
  }, [state]);

  useEffect(() => {
    const localState = getLocalStorageItem('blogPostStateKey');
    if (localState && localState !== 'Any' && localState !== 'All states') {
      updateState(localState);
      setLocations(data.filter((location) => location.locationSingleConfiguration.eaLocationState
        === localState));
    }
    let auxSelectOptions = [];
    data.forEach((location) => {
      auxSelectOptions = addNotDuplicatedArrayString(
        auxSelectOptions,
        location.locationSingleConfiguration.eaLocationState,
      );
    });
    setSelectOptions(auxSelectOptions);
  }, []);

  return (
    <div className={styles.appointmentContainer}>
      <Wrap>
        <div className={styles.header}>
          <h2>{text}</h2>
          {selectOptions && selectOptions.length > 1 && (
          <Select
            label="Location"
            firstOption="Any"
            value={state}
            onChange={updateState}
            onChangeFirstOption={() => updateState('')}
            className={styles.selectMaxWidth}
          >
            {selectOptions?.map((item) => (
              <button
                onClick={() => updateState(item)}
                type="button"
                className={item === state
                  ? selectStyles.selected
                  : selectStyles.item}
              >
                <p className={selectStyles.text}>{item}</p>
              </button>
            ))}
          </Select>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.grid}>
            {locations?.map((location) => (
              <LocationCard key={location.uri} data={location} />
            ))}
          </div>
        </div>
      </Wrap>
    </div>
  );
}

Appointment.propTypes = {
  text: string,
  data: array,
  state: string.isRequired,
  updateState: func.isRequired,
};

Appointment.defaultProps = {
  text: '',
  data: {},
};

export { Appointment };
